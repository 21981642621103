/*
Mixin to manage responsive breakpoints
@param {String} $breakpoint - Breakpoint name
@require $breakpoints
*/
@import './variables';
@mixin respond-to($breakpoint) {
    // If the key exists in the map
    @if map-has-key($breakpoints, $breakpoint) {
        // Prints a media query based on the value
        @media only screen and #{inspect(map-get($breakpoints, $breakpoint))} {
            @content;
        }
    }

    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}
