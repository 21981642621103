html,
body {
    -moz-box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    font-family: 'BMSHumanity-Regular', sans-serif;
    font-size: var(--mantine-font-size-global);
    margin: 0;
    min-height: 100%;
    padding: 0;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}
@font-face {
    font-family: 'BMSHumanity-Regular';
    src: url('assets/fonts/BMSHumanity-Regular.eot?#iefix')
            format('embedded-opentype'),
        url('assets/fonts/BMSHumanity-Regular.otf') format('opentype'),
        url('assets/fonts/BMSHumanity-Regular.woff') format('woff'),
        url('assets/fonts/BMSHumanity-Regular.ttf') format('truetype'),
        url('assets/fonts/BMSHumanity-Regular.svg#BMSHumanity-Regular')
            format('svg');
    font-weight: normal;
    font-style: normal;
}
