.container {
    display: flex;
    align-items: center;
    justify-content: center;
    .form {
        background-color: var(--mantine-color-white-0);
        display: flex;
        flex-direction: column;

        gap: var(--mantine-spacing-lg);
        border-radius: 0 0 var(--mantine-spacing-sm) var(--mantine-spacing-sm);
        .title {
            font-size: var(--mantine-font-size-2x);
            border-bottom: solid 1px var(--mantine-color-purple-0);
            padding-bottom: var(--mantine-spacing-xs);
        }
        fieldset {
            border-radius: var(--mantine-radius-sm);
            border: solid 1px var(--mantine-color-white-0);
            padding-block: var(--mantine-spacing-md) var(--mantine-spacing-2x);
            padding-inline: var(--mantine-spacing-2x) var(--mantine-spacing-2x);
            background-color: var(--mantine-color-lightChocolateWash-0);
            legend {
                border: solid 1px var(--mantine-color-mediumGrey-8);
                border-radius: var(--mantine-radius-sm);
                background-color: var(--mantine-color-white-0);
                color: var(--mantine-color-purple-0);
                font-size: var(--mantine-font-size-xl);
                padding-inline: var(--mantine-spacing-md);
            }
        }
    }
}
