@import 'assets/css/mixins';
$backgrounImage: '../../../assets/images/bg-home.jpg';
.layout {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(12, 1fr);
    height: 100vh;
    width: 100%;
    .loginCard {
        align-items: flex-start;
        background-color: var(--mantine-color-lightGrey-0);
        display: flex;
        flex-direction: column;
        gap: var(--mantine-spacing-md);
        overflow: auto;
        justify-content: center;

        padding-inline: var(--mantine-spacing-4x);
        width: 100%;
        @include respond-to('sm') {
            grid-column: 1 / -1;
            grid-row: 4 / -1;
        }
        @include respond-to('md') {
            grid-column: 1 / 8;
            grid-row: 1 / -1;
        }
        .logo {
            width: 50%;
        }
        .title {
            color: var(--mantine-color-darkGrey-0);
            font-size: var(--mantine-font-size-3x);
        }
        .subtitle {
            color: var(--mantine-color-darkGrey-0);
            font-size: var(--mantine-font-size-lg);
        }
        .summary {
            color: var(--mantine-color-darkGrey-0);
            font-size: var(--mantine-font-size-lg);
        }
        .authNumber {
            color: var(--mantine-color-darkGrey-0);
        }
    }
    .slide {
        background: url($backgrounImage) no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        box-shadow: 0.125rem 0 0.5rem 0 rgba(0, 0, 0, 0.25) inset;
        @include respond-to('sm') {
            grid-column: 1 / -1;
            grid-row: 1 / 4;
        }
        @include respond-to('md') {
            grid-column: 8 / -1;
            grid-row: 1 / -1;
        }
    }
}
