@import 'assets/css/mixins';
$backgrounImage: '../../../assets/images/bg-home.jpg';
.layout {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(12, 1fr);
    height: 100vh;
    width: 100%;
    .loginCard {
        align-items: flex-start;
        background-color: var(--mantine-color-lightGrey-0);
        display: flex;
        flex-direction: column;
        gap: var(--mantine-spacing-lg);
        grid-column: 1 / -1;
        grid-row: 1 / -1;
        justify-content: center;
        padding: var(--mantine-spacing-lg);
        width: 100%;
        .logo {
            width: 50%;
        }
        .title {
            color: var(--mantine-color-darkGrey-0);
            font-size: var(--mantine-font-size-xl);
        }
        .subtitle {
            color: var(--mantine-color-darkGrey-0);
            font-size: var(--mantine-font-size-md);
        }
        .authNumber {
            color: var(--mantine-color-darkGrey-0);
        }
    }
    .loginCard_DT {
        background-color: red;
    }
    .slide {
        display: none;
        grid-column: 1 / -1;
        grid-row: 1 / 4;
        background: url($backgrounImage) no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
    @include respond-to('md') {
        .loginCard {
            grid-column: 1 / 7;
            grid-row: 1 / -1;
            padding: var(--mantine-spacing-xl);
            .logo {
                width: 50%;
            }
            .title {
                color: var(--mantine-color-darkGrey-0);
                font-size: var(--mantine-font-size-xl);
            }
            .subtitle {
                color: var(--mantine-color-darkGrey-0);
                font-size: var(--mantine-font-size-md);
            }
            .authNumber {
                color: var(--mantine-color-darkGrey-0);
            }
        }
        .slide {
            display: block;
            grid-column: 7 / -1;
            grid-row: 1 / -1;
            background: url($backgrounImage) no-repeat center center;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
        }
    }
    @include respond-to('lg') {
        .loginCard {
            gap: var(--mantine-spacing-sm);
            grid-column: 1 / 7;
            grid-row: 1 / -1;
            padding: var(--mantine-spacing-4x);
            .logo {
                width: 50%;
            }
            .title {
                color: var(--mantine-color-darkGrey-0);
                font-size: var(--mantine-font-size-2x);
            }
            .subtitle {
                color: var(--mantine-color-darkGrey-0);
                font-size: var(--mantine-font-size-lg);
            }
            .authNumber {
                color: var(--mantine-color-darkGrey-0);
            }
        }
        .slide {
            display: block;
            grid-column: 7 / -1;
            grid-row: 1 / -1;
            background: url($backgrounImage) no-repeat center center;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
        }
    }
    @include respond-to('xl') {
        .loginCard {
            gap: var(--mantine-spacing-xl);
            .title {
                font-size: var(--mantine-font-size-3x);
            }
            .subtitle {
                font-size: var(--mantine-font-size-xl);
            }
        }
    }
}
