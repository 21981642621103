@import 'assets/css/_mixins.scss';
.container {
    padding: var(--mantine-spacing-md);
    min-width: 100%;
}
@include respond-to('lg') {
    .container {
        min-width: 1280px;
    }
}
