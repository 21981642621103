.container {
    background-color: var(--mantine-color-white-0);
    transition: box-shadow 0.3s ease-in-out;
    cursor: pointer;
    &:hover {
        box-shadow: var(--mantine-shadow-xl);
        .title {
            color: var(--mantine-color-purple-0);
        }
    }
    .infoWrapper {
        display: flex;
        flex-direction: column;
        padding-inline: var(--mantine-spacing-md);
        padding-block-end: var(--mantine-spacing-lg);
    }
    .separator {
        margin-top: var(--mantine-spacing-md);
        height: var(--mantine-spacing-2xs);
        width: 100%;
        background-color: var(--mantine-color-lightGrey-0);
    }
    .metaDescription {
        margin-top: var(--mantine-spacing-sm);
        color: var(--mantine-color-darkGrey-0);
    }
    .title {
        margin-top: var(--mantine-spacing-lg);
        color: var(--mantine-color-darkGrey-0);
        font-size: var(--mantine-font-size-lg);
    }
}
