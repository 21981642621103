.container {
    background-color: var(--mantine-color-white-0);
    transition: box-shadow 0.3s ease-in-out;
    .infoWrapper {
        display: flex;
        flex-direction: column;
        padding-inline: var(--mantine-spacing-md);
        padding-block-end: var(--mantine-spacing-lg);
    }
    .summary {
        font-size: var(--mantine-font-size-lg);
        color: var(--mantine-color-darkGrey-0);
    }
    .title {
        padding-top: var(--mantine-spacing-xl);
        padding-bottom: var(--mantine-spacing-md);
        color: var(--mantine-color-darkGrey-0);
        font-size: var(--mantine-font-size-xl);
    }
}
.horizontalLayout {
    display: flex;
    background-color: var(--mantine-color-white-0);
    .imageContainer {
        width: 40%;
    }
    .infoContainer {
        width: 60%;
        display: flex;
        flex-direction: column;
        padding-inline: var(--mantine-spacing-md);
        padding-block-end: var(--mantine-spacing-lg);
        .title {
            padding-bottom: var(--mantine-spacing-md);
            color: var(--mantine-color-darkGrey-0);
            font-size: var(--mantine-font-size-xl);
        }
        .summary {
            font-size: var(--mantine-font-size-lg);
            color: var(--mantine-color-darkGrey-0);
        }
    }
}
