@import 'assets/css/mixins';
.layout {
    width: 100%;
    background-color: var(--mantine-color-white-0);
    .contentSm {
        display: block;
    }
    .contentMd {
        display: none;
    }
    @include respond-to('md') {
        .contentSm {
            display: none;
        }
        .contentMd {
            display: block;
        }
    }
}
