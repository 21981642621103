.layout {
    background-color: var(--mantine-color-white-0);
    width: 100%;
    .noContent {
        color: var(--mantine-color-darkGrey-0);
        font-size: var(--mantine-font-size-2x);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20vh;
    }
}
