.container {
    padding: var(--mantine-spacing-md);
    position: fixed;
    bottom: 0;
    right: 0;
    height: 60vh;
    width: 30%;
    .widget {
        background-color: #fff;
        border-radius: var(--mantine-radius-md);
        display: flex;
        flex-direction: column;
        height: 100%;
        height: 100%;
        overflow: hidden;
        width: 100%;
        -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);
        > header {
            flex: 0 0 auto;
            background-color: var(--mantine-color-purple-0);
            color: white;
            padding-block: var(--mantine-spacing-sm);
            padding-inline: var(--mantine-spacing-md);
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        > section {
            flex: 1 1 auto;
            overflow-y: auto;
            position: relative;
            background-color: #fff;
            display: flex;
            flex-direction: column-reverse;
            padding: var(--mantine-spacing-md);
            .chatLine {
                display: flex;
                justify-content: space-between;
                flex-direction: column-reverse;
                .chatBubble {
                    margin-left: var(--mantine-spacing-3x);
                    border-radius: 1rem 1rem 1rem 0;
                    padding: var(--mantine-spacing-sm);
                    background-color: var(--mantine-color-mediumGrey-8);
                    color: var(--mantine-color-darkGrey-0);
                }
            }
        }
        > footer {
            flex: 0 0 auto;
            margin-top: var(--mantine-spacing-xs);
            background-color: var(--mantine-color-mediumGrey-8);
            padding: var(--mantine-spacing-xs);
            .prompt {
                align-items: center;
                background-color: var(--mantine-color-white-0);
                border-radius: var(--mantine-radius-sm);
                border: solid 1px var(--mantine-color-mediumGrey-6);
                display: flex;
                justify-content: space-between;
                padding: var(--mantine-spacing-2xs);
            }
        }
    }
}
.widgetTrigger {
    padding: var(--mantine-spacing-md);
    position: fixed;
    bottom: 0;
    right: 0;
}
