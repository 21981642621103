@import '../../../assets/css/mixins';
$backgrounImage: 'https://bmspspbucket.s3.amazonaws.com/ar/images/hero-home.png';
.layout {
    background-color: var(--mantine-color-white-0);
    width: 100%;
    main {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        color: white;
        .slideshow {
            width: 100%;
            aspect-ratio: 3 / 1;
            background: url($backgrounImage) no-repeat center center;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
        }
    }
}
/*
@include respond-to('sm') {
}
@include respond-to('md') {
}
@include respond-to('lg') {
}
@include respond-to('xl') {
}
*/
