@import 'assets/css/mixins';
.container {
    display: flex;
    justify-content: center;
    .content {
        display: flex;
        flex-direction: column;
        gap: var(--mantine-spacing-xl);
        padding: var(--mantine-spacing-xl);
        width: 100%;
        .title {
            font-size: var(--mantine-font-size-2x);
            color: var(--mantine-color-darkGrey-0);
        }
        .subtitle {
            font-size: var(--mantine-font-size-md);
            color: var(--mantine-color-darkGrey-0);
        }
        @include respond-to('md') {
            .title {
                font-size: var(--mantine-font-size-3x);
            }
            .subtitle {
                font-size: var(--mantine-font-size-xl);
            }
        }
        @include respond-to('xl') {
            padding: var(--mantine-spacing-xl);
            width: 1280px;
        }
    }
}
.themeAmber {
    background-color: var(--mantine-color-amber-0);
}
.themePeach {
    background-color: var(--mantine-color-peach-0);
}
.themeOlive {
    background-color: var(--mantine-color-olive-0);
}
.themeAlmond {
    background-color: var(--mantine-color-almond-0);
}
.themeLightChocolateWash {
    background-color: var(--mantine-color-lightChocolateWash-0);
}
