.contactCard {
    background-color: var(--mantine-color-white-0);
    padding: var(--mantine-spacing-xs);
    display: flex;
    align-items: flex-start;
    gap: var(--mantine-spacing-sm);
    border-radius: var(--mantine-radius-sm);
    .info {
        .title {
            color: var(--mantine-color-darkGrey-0);
            font-size: var(--mantine-font-size-md);
        }
        .subtitle {
            color: var(--mantine-color-purple-0);
            font-size: var(--mantine-font-size-sm);
        }
        .summary {
            color: var(--mantine-color-darkGrey-0);
        }
    }
}
