.container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .label {
        font-size: var(--mantine-font-size-2x);
        color: var(--mantine-color-darkGrey-0);
    }
}
