.container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    .form {
        width: 100%;
        background-color: var(--mantine-color-white-0);
        display: flex;
        flex-direction: column;
        padding: var(--mantine-spacing-2x);
        gap: var(--mantine-spacing-lg);
        border-radius: var(--mantine-spacing-xs);
        .disclaimer {
            color: var(--mantine-color-darkGrey-0);
            font-size: var(--mantine-font-size-sm);
        }
    }
    .formTabs {
        background-color: var(--mantine-color-white-0);
        display: flex;
        flex-direction: column;
        padding: var(--mantine-spacing-2x);
        gap: var(--mantine-spacing-lg);
        border-radius: 0 0 var(--mantine-spacing-xs) var(--mantine-spacing-xs);
        .disclaimer {
            color: var(--mantine-color-darkGrey-0);
            font-size: var(--mantine-font-size-sm);
        }
    }
}
