.layout {
    background-color: var(--mantine-color-white-0);
    width: 100%;
    .article {
        color: var(--mantine-color-darkGrey-0);
        .title {
            font-size: var(--mantine-font-size-2x);
            border-bottom: solid 1px var(--mantine-color-purple-0);
        }

        .description {
            margin-top: var(--mantine-spacing-md);
            font-size: var(--mantine-font-size-lg);
        }
    }
    .article:not(:first-of-type) {
        margin-top: var(--mantine-spacing-2x);
    }
}
