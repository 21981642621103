@import 'assets/css/mixins';
.container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--mantine-color-lightGrey-0);
    padding-block: var(--mantine-spacing-xl);
    footer {
        width: 100%;
        .xsFooter {
            display: block;
        }
        .smFooter {
            display: none;
        }
    }
    @include respond-to('sm') {
        footer {
            .xsFooter {
                display: none;
            }
            .smFooter {
                width: var(--mantine-breakpoints-lg);
                display: block;
            }
        }
    }
}
