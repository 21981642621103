@import '../../../assets/css/mixins';
.container {
    //position: relative;
    align-items: center;
    background-color: var(--mantine-color-white-0);
    border-bottom: solid 1px var(--mantine-color-lightGrey-0);
    display: flex;
    justify-content: space-between;
    padding-block: var(--mantine-spacing-md);
    width: 100%;
    //box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.125);
    //z-index: 99;
    nav {
        width: 100%;
        .sm {
            display: block;
        }
        .md {
            display: none;
        }
        @include respond-to('lg') {
            .md {
                align-items: center;
                display: flex;
                justify-content: space-between;
                width: 100%;
                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    li {
                        display: inline-block;
                        padding-inline: var(--mantine-spacing-lg);
                    }
                    & li:first-child {
                        padding-left: 0;
                    }
                    & li:last-child {
                        padding-right: 0;
                    }
                    span {
                        font-size: var(--mantine-font-size-md);
                    }
                }
            }
            .sm {
                display: none;
            }
        }
    }
}
