@import 'assets/css/_mixins.scss';
.layout {
    width: 100%;
    background-color: var(--mantine-color-white-0);
    .container {
        padding: var(--mantine-spacing-md);
        min-width: 100%;
    }
    @include respond-to('lg') {
        .container {
            min-width: 1280px;
        }
    }
}
