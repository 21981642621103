@import 'assets/css/mixins';
.container {
    align-items: center;
    background-color: var(--mantine-color-white-0);
    border-bottom: solid 1px var(--mantine-color-lightGrey-0);
    display: flex;
    justify-content: center;
    padding-block: var(--mantine-spacing-md);
    padding-inline: var(--mantine-spacing-lg);
    width: 100%;

    header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
        .projectLogoContainer {
            width: 150px;
        }
        .programLogoContainer {
            width: 100px;
        }
        @include respond-to('sm') {
            .projectLogoContainer {
                width: 200px;
            }
            .programLogoContainer {
                width: 150px;
            }
        }
        @include respond-to('lg') {
            .projectLogoContainer {
                width: 250px;
            }
            .programLogoContainer {
                width: 200px;
            }
        }
    }
}
