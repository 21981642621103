.layout {
    background-color: var(--mantine-color-white-0);
    width: 100%;
    .noContent {
        color: var(--mantine-color-darkGrey-0);
        font-size: var(--mantine-font-size-2x);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20vh;
    }
}
.argentinaDisplay {
    background-color: var(--mantine-color-lightGrey-0);
    display: flex;
    gap: var(--mantine-spacing-md);
    padding: var(--mantine-spacing-md);
    .imageContainer {
        aspect-ratio: 16/9;
        overflow: hidden;
        width: 50%;
        img {
            object-fit: cover;
        }
    }
    .infoContainer {
        aspect-ratio: 16/9;
        color: var(--mantine-color-darkGrey-0);
        display: flex;
        flex-direction: column;
        font-size: var(--mantine-font-size-md);
        justify-content: space-between;
        overflow: hidden;
        padding: var(--mantine-spacing-sm);
        width: 50%;
        .title {
            font-size: var(--mantine-font-size-xl);
        }
        .info {
            margin-top: var(--mantine-spacing-md);
        }
        .button {
            display: flex;
            justify-content: flex-end;
            a {
                background-color: var(--mantine-color-purple-0);
                padding-block: var(--mantine-spacing-xs);
                padding-inline: var(--mantine-spacing-sm);
                color: white;
                border: solid 1px var(--mantine-color-purple-0);
                &:hover {
                    text-decoration: none;
                    background-color: white;
                    border: solid 1px var(--mantine-color-purple-0);
                    color: var(--mantine-color-purple-0);
                }
            }
        }
    }
}
.argentinaDisplay:not(:first-of-type) {
    margin-top: var(--mantine-spacing-xl);
}
