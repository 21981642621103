.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: var(--mantine-color-white-0);
    header {
        flex: 0 0 auto;
        .separator {
            margin-top: var(--mantine-spacing-md);
            height: var(--mantine-spacing-2xs);
            width: 100%;
            background-color: var(--mantine-color-lightGrey-0);
        }
        .title {
            flex: 1;
            margin-top: var(--mantine-spacing-2x);
            color: var(--mantine-color-darkGrey-0);
            font-size: var(--mantine-font-size-2x);
        }
    }
    .summary {
        flex: 1 0 auto;
        margin-top: var(--mantine-spacing-md);
        font-size: var(--mantine-font-size-lg);
        color: var(--mantine-color-darkGrey-0);
    }
    footer {
        flex: 0 0 auto;
    }
}
